.footer {
  background-color: #2c3e50;
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

.container {
  width: 80%;
  margin: auto;
  overflow: hidden;
}
